@import "colors.scss";

footer{
    background-color: $green;
    height: 90px;

    .footer-list {
        display: flex;
        justify-content: center;
        height: 100%;
        align-items: center;
        text-align: center;

        span{
            padding: 20px;
        }
        a {
            text-decoration: none;
            color: white;
        }
    }
}