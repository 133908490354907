.app-container .alert{
    top: 15px;
    right: 15px;
    position: fixed;
    width: 50%;
    z-index: 2;
}
@media only screen and (max-width: 768px) {
   
    .app-container {
        .alert {
            width: 93%;
        }
    
        .btn-container-flex {
            margin-top: 1rem;
        }
    }
}