@import "colors.scss";

.card {

    &-container {
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;

        .card {
            color: $white;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            flex: 1;
            height: 256px;
            border: 1px solid;
            min-width: 160px;
            border-radius: 1rem;

            img {
                width: 4.00206rem;
            }
        }

        .card.left-card {
            background: linear-gradient(-10deg, $dark-blue 35%, $blue 35%);
            margin-left: 0px;
        }

        .card.right-card {
            background: linear-gradient(-10deg, $dark-red 62%, $red 62%);
            margin-right: 0px;
        }

        .card-title {
            font-family: 'Asap';
            font-size: 1.5rem;
            padding-top: 14px;
        }

        .card-number {
            font-family: 'Asap';
            font-size: 3rem;
            font-weight: 700;
        }

    }

    &-wrapper {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        column-gap: 1.5rem;
        margin-top: 3rem;
    }

    &-dpe {
        width: 33.75rem;
        height: 32.5rem;
        background-color: $white;
        border-radius: 1rem;
        flex: 1 1;
    }

    &-header-title {
        width: 33.75rem;
        height: 5.5rem;
        flex-shrink: 0;
        background: $green;
        border-radius: 1rem 1rem 0rem 0rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    @media only screen and (max-width: 768px) {
        &-dpe {
            width: 90%;
        }

        &-header-title {
            width: 100%;
        }

        &-wrapper {
            row-gap: 10.5rem;
        }
    }


    &-text {
        color: $white;
        text-align: center;
        font-family: "Asap";
        font-size: 2rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;

        &_lightblue {
            color: $lightblue;
        }
    }
}

.estimation {
    &-container {
        position: relative;
        max-width: 1110px;
        padding: 0;
        margin: 0 auto;
        display: flex;
        grid-gap: 1rem;
        gap: 1rem;
        flex-wrap: wrap;
        flex-direction: column;
        top: 0rem !important;

        &-wrapper {
            display: flex;
            justify-content: center;
            flex-direction: column;
            flex-wrap: wrap;
            align-items: center;
        }
    }

    &-header {
        text-align: center;
        width: 67%;
    }

    &-middle-text {
        position: relative;
        max-width: 1110px;
        width: 67%;
        padding: 0;
        margin: 3rem auto;
        text-align: center;
    }

    &-img {
        width: 10rem;
        height: 5.68806rem;
        position: absolute;

        &__article {
            max-width: 100%;
            width: 29.75rem;
            height: 18rem;
            border-radius: 1rem;
        }
    }

    @media only screen and (max-width: 768px) {
        &-img {
            transform: rotate(90deg);
            scale: 70%;
        }
    }
}



.energy {

    &-container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding: 2rem;
    }

    &-wrapper {
        width: fit-content;
    }

    &-level {
        color: $white;
        text-align: center;
        font-family: "Asap";
        font-size: 1.8rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: uppercase;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 0.5rem;
        min-width: 4.24194rem;
        min-height: 1.78194rem;
        transition: all 0.4s ease;

        &-text {
            text-align: left;
            padding-left: 0.5rem;
        }

        &-triangle {
            border-width: 1.1rem 0 1.05rem 1.2rem;
            border-style: solid;
            width: 0;
            height: 0;
        }

        &_hover:hover {
            transform: scale(1.1);
            cursor: pointer;
        }

        &_scale {
            transform: scale(1.1);

            .energy-level-text {
                padding: 0.55rem 0.5rem;
                //border-bottom: 0.2rem solid #686E23;
            }

            .energy-level-triangle {
                border-width: 1.6rem 0 1.6rem 1.6rem;
            }

            //transform: scale(1.1);
        }

    }

    &-text {
        color: $green;
        text-align: center;
        font-family: "Asap";
        font-size: 2rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }


}

.higt {
    height: 200%;
}

.faq {
    margin-bottom: 2.5rem;

    &:last-child {
        margin-bottom: 0;
    }

    &-question {
        font-size: 1rem;
        font-style: normal;
        line-height: normal;

        font-family: "Open Sans Bold";
        font-weight: 700;
        color: $green;
    }

    &-answer {
        font-size: 1rem;
        font-style: normal;
        line-height: normal;

        font-family: "Open Sans";
        font-weight: 400;
        color: $grey;
    }
}