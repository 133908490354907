@import "colors.scss";

.progress-container {
  width: 100%;
  height: 50px;
  margin-top: 2rem;

  .progress {
    height: 100%;
    border-radius: 50px;
    box-shadow: inset 0 0 0 3px $red;
    background-color: transparent;

    .progress-bar {
      background-color: $red;
      border-radius: 50px;
      color: $white;
      text-align: center;
      font-family: "Open", sans-serif;
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }
}

header.estimation-header {
  font-family: "Asap";

  @media only screen and (max-width: 768px) {
    .header-title {
      top: 39%;
      font-size: 35px;
    }

    .header-text {
      font-size: 20px;
      top: 100%;
    }

    .header-title-text {
      font-size: 35px;
      line-height: 50px;
      width: 100%;
      font-weight: 700;
      letter-spacing: normal;
    }
  }

  @media only screen and (min-width: 416px) {
    .header-title {
      top: 55%;
    }

    .header-text {
      font-size: 25px;
      top: 135%;
    }

    .header-title-text {
      font-size: 3em;
      line-height: 50px;
      width: 100%;
      font-weight: 700;
      letter-spacing: normal;
    }
  }

  .header-title {
    &__strong {
      color: white;
      font-weight: 700;
    }
  }

  .header-title-text {
    line-height: 50px;
    width: 100%;
    font-weight: 700;
    letter-spacing: normal;
  }

  .header-text {
    text-align: center;
    color: white;
    position: absolute;
    width: 100%;
  }

  .header-address {
    text-align: center;
    font-size: 25px;
    color: white;
    top: 53%;
    position: absolute;
    width: 100%;

    &.estimation-result {
      top: 71%;
    }
  }
}

header {
  position: relative;

  @media only screen and (max-width: 510px) {
    .header {
      &-title {
        top: 85px;

        &-contact {
          top: 160px;
          color: white;
          position: absolute;
          font-size: 46px;
          line-height: 50px;
          text-align: center;
          width: 100%;
          font-size: 3em;
          font-weight: 700;
          letter-spacing: normal;
          text-align: center;
        }
      }
    }
  }

  @media only screen and (min-width: 510px) {
    .header {
      &-title {
        top: 160px;

        &-contact {
          top: 160px;
          color: white;
          position: absolute;
          font-size: 46px;
          line-height: 50px;
          text-align: center;
          width: 100%;
          font-size: 3em;
          font-weight: 700;
          letter-spacing: normal;
          text-align: center;
        }
      }
    }
  }

  .header {
    &-title {
      color: $white;
      font-family: "Asap";
      font-size: 2rem;
      font-style: normal;
      font-weight: 700;
      line-height: 2.5rem;
      //margin-top: 3.17rem;

      &__uppercase {
        text-transform: uppercase;
        font-size: 1.5rem;
      }

      &__strong {
        font-size: 4rem;
        line-height: 4.25rem;
        /* 106.25% */
        letter-spacing: -0.08rem;
        font-style: normal;
        text-transform: none;
      }

      &__short {
        margin-top: 2rem;
      }

      &__sub {
        margin: 1rem 0;
      }
    }

    &-container {
      max-width: 1110px;
      margin: 0 auto;
      height: 100%;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      justify-content: center;
      position: relative;
    }

    &-wrapper {
      width: 57%;
      position: relative;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      margin-top: 6.69rem;
    }

    &-inner {
      display: flex;
      margin-top: 1rem;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      position: absolute;
      top: 0;
    }
  }

  &.header-img {
    height: 576px;
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
    background-color: lightgray;
    background-image: url("../assets/header-preview.png");
  }
}

.header-sub-title {
  width: 100%;
  color: white;
}

@media only screen and (max-width: 768px) {
  header {
    &.header-img {
      height: 100vh;
      background-image: url("../assets/header-preview_mobile.png");
    }

    .header {
      &-wrapper {
        width: 100%;
      }

      &-title {
        text-align: center;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-items: center;

        &__uppercase {
          font-size: 1.2rem;
        }

        &__strong {
          width: 80%;
          font-size: 2.6rem;
          line-height: 3.25rem;
        }
      }
    }
  }

  .header-sub-title {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
