.chat-widget {
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

    display: flex;
    flex-direction: column;
    position: absolute;
    justify-content: space-between;
    bottom: 80px;
    right: 0px;
    width: 375px;
    height: 500px;
    transition: right 0.3s ease;
    border-radius: 15px;

    &-container {
        position: fixed;
        bottom: 20px;
        right: 20px;
        z-index: 999;
        transition: right 0.3s ease;
    }

    &-header {
        background-color: #02B5CC;
        color: #fff;
        padding: 10px;
        text-align: center;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
    }

    &-footer {
        display: flex;
        align-items: center;
        padding: 0px 10px 10px 10px;
        justify-content: space-between;
        flex-direction: row;
        flex-wrap: nowrap;

        &-wrapper {
            background-color: #f0f0f0;
            border-bottom-left-radius: 15px;
            border-bottom-right-radius: 15px;
        }

        & a {
            color: #555;
        }
    }

    &-messages {
        padding: 20px;
        overflow-y: auto;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        flex-wrap: nowrap;

        &::-webkit-scrollbar {
            width: 5px;
        }

        &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        }

        &::-webkit-scrollbar-thumb {
            background-color: #02B5CC;
        }
    }

    &-message {
        background-color: #f0f0f0;
        border-radius: 5px;
        padding: 10px;
        margin: 5px 0;
        opacity: 0;
        transform: translateX(-20px);
        animation: slide-in 0.5s ease forwards;
    }

    @keyframes slide-in {
        to {
            opacity: 1;
            transform: translateX(0);
        }
    }

    &-input {
        display: flex;
        align-items: center;
        padding: 10px;
    }

    &-button {
        background-color: #02B5CC;
        color: #fff;
        padding: 20px;
        border: none;
        border-radius: 50%;
        cursor: pointer;


        &__closed {
            animation: bounce 1s ease 10s 2 forwards;
        }
    }

    @keyframes bounce {

        0%,
        20%,
        50%,
        80%,
        100% {
            transform: translateY(0);
        }

        40% {
            transform: translateY(-10px);
        }

        60% {
            transform: translateY(-5px);
        }
    }

    &-icon {
        &-container {
            display: flex;
            color: #fff;
            flex-direction: row;
            align-items: center;
            height: 45px;
            justify-content: center;
            margin-top: -45px;
        }

        & path {
            stroke: #fff;
            stroke-dasharray: 1000;
            stroke-dashoffset: 1000;
            animation: write-chat 4s linear infinite;
        }
    }

    @keyframes write-chat {
        0% {
            stroke-dashoffset: 1000;
        }

        100% {
            stroke-dashoffset: 0;
        }
    }


    input[type="text"] {
        flex: 1;
        border: 1px solid #ccc;
        border-radius: 5px;
        padding: 10px;
    }

    &-send-button {
        background-color: #02B5CC;
        color: #fff;
        border: none;
        border-radius: 5px;
        padding: 10px 20px;
        margin-left: 10px;
        cursor: pointer;
    }
}

.dot {
    animation: jumpingAnimation 2s linear infinite;
    opacity: 1;
    font-size: 30px;
}

.dot:nth-child(1) {
    animation-delay: 0s;
}

.dot:nth-child(2) {
    animation-delay: 0.3s;
}

.dot:nth-child(3) {
    animation-delay: 0.6s;
}

@keyframes jumpingAnimation {
    0% {
        transform: translate(0, 0);
    }

    16% {
        transform: translate(0, -5px);
    }

    33% {
        transform: translate(0, 0);
    }

    100% {
        transform: translate(0, 0);
    }
}