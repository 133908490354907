.maps {
    &-street-view {
        height: 19.5rem;
        border-radius: 1rem;
    }
}

.street-view {
    
    &-pano {
        float: left;
        height: 100%;
        width: 100%;
        border-radius: 1rem;
    }

     
}