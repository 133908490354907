@import "colors.scss";
@import "form.scss";
@import "button.scss";
@import "alert.scss";
@import "modal.scss";

@font-face {
    font-family: "Asap";
    src: local("Asap"), url(../fonts/Asap/Asap-Regular.ttf) format("truetype");
}

@font-face {
    font-family: "Asap";
    font-weight: 900;
    src: local("Asap"), url(../fonts/Asap/Asap-Bold.ttf) format("truetype");
}

@font-face {
    font-family: "Open Sans Light";
    font-weight: 300;
    src: local("OpenSans"), url(../fonts/OpenSans/OpenSans-Light.ttf) format("truetype");
}

@font-face {
    font-family: "Open Sans";
    font-weight: 400;
    src: local("OpenSans"), url(../fonts/OpenSans/OpenSans-Regular.ttf) format("truetype");
}

@font-face {
    font-family: "Open Sans Medium";
    font-weight: 500;
    src: local("OpenSans"), url(../fonts/OpenSans/OpenSans-Medium.ttf) format("truetype");
}

@font-face {
    font-family: "Open Sans Bold";
    font-weight: 700;
    src: local("OpenSans"), url(../fonts/OpenSans/OpenSans-Bold.ttf) format("truetype");
}

a {
    color: white;
    text-decoration: none;
}

.app-container {
    display: flex;
    flex-direction: column;
    background-color: $background-light;
}

.body-container {
    background-color: $background-light;
    margin: 1rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Asap", sans-serif;
}

header {
    font-family: "Asap", sans-serif;
}

.justify {

    @media only screen and (max-width: 991px) {
        display: none;
    }

    @media only screen and (min-width: 992px) {
        display: block;
    }

    justify-content: center;
    display: flex;
}

.justify-flex {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    @media only screen and (max-width: 991px) {
        display: none;
    }
}

.space-between {
    justify-content: space-between;
    width: 100%;
}

.no-padding {
    padding: 0 !important;
}

.text {
    &__left {
        text-align: left !important;
    }

    &__right {
        text-align: right !important;
    }
}

.marginVertical32 {
    margin: 2rem 0;
}

.marginBottom32 {
    margin-bottom: 2rem;
}