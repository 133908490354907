.modal-dialog {
    
    &-content {
        border-radius: 1rem!important;
        background: #FFF;
        padding: 3rem;
    }

    &-title {
        padding: 0;
        color: $green;
        font-family: "Asap";
        font-size: 2rem!important;
        font-style: normal!important;
        font-weight: 700!important;
        line-height: normal!important;
    }

    &-body {
        padding: 0!important;

        &_text {
            color: $grey;
            font-family: 'Open Sans';
            font-size: 1rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;

            &__strong {
                font-family: 'Open Sans Bold';
            }

            &__phone {
                font-family: 'Open Sans Bold';

                & a {
                    text-decoration: none;
                    color: $green;
                }
            }
        }

        & input {
            background-color: #F2F6F8;
            border: none;
        }
    }

    &-header {
        border-bottom-width: 0px!important;
        width: 90%;
        padding: 0 0 1rem 0!important;
    }
    
    &-footer {
        border-top-width: 0px!important;
        padding: 1.5rem 0 0 0!important;
    }

    & .btn-close {
        position: absolute;
        top: 1rem;
        right: 1rem;
        color: $green;

        background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'%3e%3cpath d='M15.6229 11.9596C15.8743 12.211 16 12.5163 16 12.8754C16 13.2346 15.8743 13.5398 15.6229 13.7912L13.7912 15.6229C13.5398 15.8743 13.2346 16 12.8754 16C12.5163 16 12.211 15.8743 11.9596 15.6229L8 11.6633L4.0404 15.6229C3.789 15.8743 3.48373 16 3.12458 16C2.76543 16 2.46016 15.8743 2.20875 15.6229L0.377104 13.7912C0.125701 13.5398 0 13.2346 0 12.8754C0 12.5163 0.125701 12.211 0.377104 11.9596L4.3367 8L0.377104 4.0404C0.125701 3.789 0 3.48373 0 3.12458C0 2.76543 0.125701 2.46016 0.377104 2.20875L2.20875 0.377104C2.46016 0.125701 2.76543 0 3.12458 0C3.48373 0 3.789 0.125701 4.0404 0.377104L8 4.3367L11.9596 0.377104C12.211 0.125701 12.5163 0 12.8754 0C13.2346 0 13.5398 0.125701 13.7912 0.377104L15.6229 2.20875C15.8743 2.46016 16 2.76543 16 3.12458C16 3.48373 15.8743 3.789 15.6229 4.0404L11.6633 8L15.6229 11.9596Z' fill='%2307636D' /%3e%3c/svg%3e") center/1em auto no-repeat;
    }
}