@import "colors.scss";

.steps-container{
    display: flex;
    width: 100%;
    justify-content: flex-start;
    margin-top: 2rem;

    @media only screen and (max-width: 510px) { 
        .step-element{
            margin-left: 5%;
            margin-right: 5%;
    }
    }
    @media only screen and (min-width: 510px) { 
        .step-element{
            margin-right: 50px;
        }
    }
    .step-element{
        background: $red;
        width: 64px;
        height: 64px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        font-size: 23px;
        font-weight: 600;
        
        &.active{
            cursor: pointer;
        }
        
        &:not(.active){
            background: transparent;
            border: 4px solid;
        }
    }
}

@media only screen and (max-width: 768px) {
    .steps-container {
        justify-content: center;
    }
}