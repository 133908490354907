@import "colors.scss";

.language {
    &-custom-toogle {
        color: $white;
        text-align: center; 
        font-family: 'Open Sans';
        font-size: 1rem;
        font-style: normal;
        font-weight: 700;
        line-height: 2.5rem; /* 250% */
        text-transform: uppercase;
        border-radius: 2.1875rem;
        border: 3px solid #FFF;
        padding: 0.3rem 1.5rem;
        text-decoration: none;

        &:hover {
            color: $white;
        }
    }

    &-dropdown {
        min-width: fit-content!important;
        margin-left: -0.35rem!important;
    }

    &-item {
        &:active {
                background-color: $grey!important;            
        }
    }
}