@import "colors.scss";

/* override bootstrap input default style */
.app-container {
  @media only screen and (max-width: 768px) {

    .mb-3 {
      display: block;
      margin-bottom: 0 !important;
    }

    .form {
      &-select-property-form {
        margin-top: 0;
        margin-bottom: 1rem;
      }

      &-control-pc-form {
        margin-top: 0;
        margin-bottom: 1rem;
      }

      &-group-property-form {
        margin-top: 0;
        margin-bottom: 1rem;
      }

      &-control {

        &-container {
          flex-wrap: wrap;
          flex-direction: row;
        }

        &-label {
          width: 100%;
          margin-top: 1rem;
          margin-bottom: 1rem;
        }

        &-lg {
          font-size: 1rem;
        }
      }

      &-checkbox {
        padding-top: 0 !important;
      }
    }


    .radio {
      &_container {
        width: 70%;
      }
    }
  }



  @media only screen and (min-width: 768px) {
    .mb-3 {
      display: flex;
    }

    .form {
      &-group-property-form {
        margin-top: 0;
        position: relative;
      }

      &-control-pc-form-country-code {
        position: absolute;
        top: 0;
        right: 25px;
        height: 100%;
        display: flex;
        align-items: center;
      }
    }
  }

  .form {
    &-row-container {
      &__center {
        align-items: center;
      }
    }

    &-control {
      background-color: $background-light;
      border: none;

      &:hover,
      &:active,
      &:focus-within {
        background-color: $background-light;
      }

      &-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 100%;
      }

      &__radio {
        color: $green;
        font-family: "Open", sans-serif;
        font-size: 1rem;
        font-style: normal;
        font-weight: 700;
        line-height: 1.5rem;
        margin: auto;
      }

      /* Chrome, Firefox, Opera, Safari 10.1+ */
      &::placeholder {
        font-weight: 600;
        color: $grey;
        opacity: 1;
        /* Firefox */
        background-color: $background-light;
      }

      /* Internet Explorer 10-11 & Microsoft Edge & default form select style */
      &:-ms-input-placeholder,
      &::-ms-input-placeholder {
        font-weight: 600;
        color: $grey;
        background-color: $background-light;
        font-size: 1rem;
      }

      &-lg {
        font-size: 1rem;
      }
    }

    &-select {
      color: $green;
      font-weight: 600;
      background-color: $background-light;
      border: none;

      option {
        color: $green;
        font-weight: 600;
      }

      &-lg {
        font-size: 1rem;
      }
    }

    &-check-group {
      display: flex;
      margin-right: 0;
      margin-left: auto;
    }

    &-check {
      margin-left: 0.8rem;
    }

    &-check-input:checked {
      background-color: $blue;
      border-color: $blue;
    }

    &-checkbox {
      padding-top: 2rem;
    }

    &-group {
      margin-top: 1rem;
    }

    &-label {
      color: $green;
      font-family: "Open", sans-serif;
      font-size: 1rem;
      font-style: normal;
      font-weight: 700;
      line-height: 1.5rem;
      margin: auto;
      margin-bottom: 0.5rem;
    }
  }

  .form-checkbox {
    .form-label {
      margin-bottom: 0rem;
    }
  }

  .radio-form-group {
    display: flex;
    margin-top: 1rem;
  }

  .box-container {
    background: $white;
    border-radius: 1rem;
    position: relative;
    max-width: 1110px;
    margin-top: -4.5rem;
    padding: 2rem;
  }

  .cgu-text {
    p {
      font-family: "Open", sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 1rem;
      line-height: 1.2rem;
      color: $grey;
    }

    a {
      text-decoration: none;
      color: $blue;
    }
  }
}

.radio {
  &_container {
    width: 40%;
  }
}

@media only screen and (max-width: 1500px) {
  .app-container .box-container {
    top: -4.5rem;
  }
}

@media only screen and (max-width: 768px) {
  .app-container .box-container {
    top: 0rem;
  }
}