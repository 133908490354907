@import "colors.scss";

.estimation-box {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  margin: 1rem;

  @media only screen and (max-width: 768px) {
    .box {
      top: 85px;
    }
  }

  .box {
    flex: 1;
    height: 328px;
    min-width: 285px;
    border: 4px solid #f2f6f8;
    background: #f2f6f8;
    border-radius: 8px;
    display: inline-block;

    h2 {
      margin-top: 12px;
      text-align: center;
      font-style: normal;
      font-weight: bold;
      font-size: 36px;
      color: $green;
      line-height: 41px;
    }
  }
}

.box:hover {
  background: $green;
  border: 4px solid $red;
  cursor: pointer;

  h2,
  p,
  div {
    color: white;
  }
}

.logo {
  margin-top: 32px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.buttonsText {
  margin-top: 27px;
  margin-left: 10%;
  margin-right: 10%;

  &>div {
    display: flex;
    column-gap: 0.5rem;
    flex-wrap: nowrap;

    &>img {
      height: fit-content;
    }
  }
}

p {
  font-family: "Open", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: $grey;
}