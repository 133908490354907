.search-input-container {
    width: 100%;
    margin-top: 2rem;

    >div {
        display: flex;
        justify-content: flex-start;
    }

    #search-input {
        align-self: center;
        font-size: 1rem;
        font-family: "Open Sans", sans-serif;
        color: #FFF;
        padding: 1.25rem 1.875rem 1.25rem 5.75rem;
        border-radius: 3.125rem;
        width: 92%;
        font-weight: 700;
        border: 3px solid #FFF;

        background: url("../assets/location-icon-vector.svg") transparent 25px 50% no-repeat;
        background-size: 34px;
        bottom: 105px;

        &::placeholder {
            color: white;
        }
    }

    .suggestion-item-container {

        .suggestion-item-list {
            margin-right: 2.5rem;
            margin-left: 5.625rem;
            box-shadow: rgba(0, 0, 0, 0.24) 0 0.2rem 0.5rem;
        }

        .suggestion-item {
            background-color: white;
            cursor: pointer;
            padding: 0.625rem;
            display: flex;
            align-items: center;
            border-bottom: 1px solid #a2a2a2bd;

            &:last-child {
                border-bottom: none;
            }

            div.suggestion-icon {
                padding-right: 0.625rem;
            }

            div.suggestion-description {
                margin-bottom: -0.3125rem;
            }

            &.active {
                background: #f5f5f5;
            }
        }
    }

    .input-container {
        position: relative;

        &:after {
            content: '';
            background: url('../assets/search-icon-vector.svg') transparent no-repeat;
            background-size: 1.8rem;
            width: 1.8rem;
            height: 1.8rem;
            position: absolute;
            right: 5rem;
            top: 1.2rem;
        }
    }

    .autocomplete-dropdown-container {
        z-index: 3;
        position: absolute;
        top: 4.25rem;
        width: 70%;
    }
}

@media only screen and (max-width: 768px) {
    .search-input-container {

        >div {
            justify-content: center;
        }

        .autocomplete-dropdown-container {
            width: 100% !important;
        }

        .suggestion {
            &-item-list {
                margin-right: 2.5rem !important;
                margin-left: 2.5rem !important;
            }
        }

        .input-container {
            &:after {
                display: none;
            }
        }
    }
}