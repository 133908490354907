@import "colors.scss";

.article-container .container {
  max-width: 1110px;
}

.title {
  color: $green;
  font-family: "Asap";
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;

  &-subtitle {
    font-size: 3rem;
    font-style: normal;
    line-height: 3.125rem;
    /* 104.167% */
    letter-spacing: -0.06rem;
    text-transform: none;
  }

  &-lightblue {
    color: $lightblue;
  }
}

.article-container {
  position: relative;

  &__margin-top {
    margin-top: 5rem;
  }
}

.article {
  border-radius: 1rem;
  background-color: $white;
  margin-bottom: 5rem;
  height: 25.5rem;
  position: relative;

  & > div {
    padding: 0;
  }

  &-grid-layout {
    display: grid;
    grid-template-columns: 1.75fr 2.6fr;
    column-gap: 3.5rem;
  }

  &-transparent {
    background: transparent;
    margin-bottom: 5rem;
    padding: 0px;
  }

  &__fit-content {
    height: fit-content;

    & > div {
      padding: 0;
    }
  }

  &-column {
    padding: 4rem;

    &__no-borders {
      padding: 0;
      --bs-gutter-x: 0;
    }
  }

  &-cell {
    padding: 4rem;
    overflow: hidden;
    z-index: 1;
    position: relative;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;

    &-header {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 1rem;
      margin-bottom: 1.63rem;
      align-items: center;
    }

    &-img {
      padding: 0rem;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1;

      & img {
        border-radius: 1rem;
      }

      &__trees-bg {
        width: 26.1875rem;
        height: 21.5rem;
      }
    }

    &-text {
      padding-right: 3.5rem !important;
      padding-left: 3.5rem !important;
    }

    &-icon {
      width: 7.5rem;
      height: 7.5rem;
      flex-shrink: 0;
      border-radius: 1rem;
      background: $green;
      display: flex;
      align-items: center;
      justify-content: center;

      & img {
        width: 3.72975rem;
        height: 3.87506rem;
        max-height: 21.5rem;
        flex-shrink: 0;
      }
    }

    &__no-borders {
      padding-right: 0rem !important;
      padding-left: 0rem !important;
    }

    &__center {
      display: flex;
      justify-content: center;
    }

    &__thin-padding-left {
      padding-left: 2rem !important;
    }

    &__thin-padding-right {
      padding-right: 2rem !important;
    }

    &__full-width {
      padding: 4rem;
    }

    &__sale-form {
      &_img {
        padding-bottom: 2rem !important;
      }
    }
  }

  &__top {
    margin-top: 5rem !important;
  }

  &__first {
    background-color: transparent;

    &:before {
      content: "";
      position: absolute;
      left: 0;
      z-index: 0;
      width: calc(100% - 5rem);
      height: 100%;
      border-radius: 1rem;
      background-color: $white;
    }
  }

  &__sale-form {
    background-color: transparent;

    &:before {
      content: "";
      position: absolute;
      right: 0;
      z-index: 0;
      width: calc(100% - 5rem);
      height: 100%;
      border-radius: 1rem;
      background-color: $white;
    }
  }
}

.boldText {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  color: $grey;
}

.text {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: $grey;
}

.img {
  &-container {
    position: relative;
    z-index: 1;
    display: grid;
    align-items: center;
    justify-items: center;
    justify-content: space-between;
    align-content: center;
    grid-column-gap: 1rem;
    grid-template-columns: repeat(2, 1fr);
  }

  &-first-layer {
    z-index: 0;
    position: absolute;
    top: -2rem;

    &__down {
      bottom: -2rem;
      top: auto;
    }
  }

  &-center-layer {
    z-index: 0;
    position: absolute;
    bottom: -2rem;
    width: 17.38706rem !important;

    &__top {
      top: 1rem;
      width: 12.1875rem !important;
      height: 5.0625rem;
    }
  }

  &-two {
    img {
      width: 13.5rem;
      height: 17.5rem;
    }
  }

  &-spec-bg {
    width: 31.375rem;
    height: 99%;
    flex-shrink: 0;
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: flex-end;
    z-index: 0;
    max-width: 100%;
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;

    &:after {
      content: "";
      position: absolute;
      top: -8%;
      left: -12.5%;
      height: 110%;
      width: 27%;
      background: #fff;
      transform: rotate(16deg);
    }
  }
}

.img-full {
  max-height: max-content !important;
}

@media (max-width: 1000px) {
  .img {
    &-spec-bg {
      border-top-right-radius: 0rem;
      border-bottom-right-radius: 0rem;

      &:after {
        display: none;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .title {
    font-size: 1.2rem;

    &-subtitle {
      font-size: 2.1rem;
      line-height: 2.5rem;
    }
  }

  .article {
    height: fit-content;
    margin-bottom: 2rem;

    &-column {
      padding: 2rem;

      &__no-borders {
        padding: 0;
        --bs-gutter-x: 0;
      }
    }

    &-grid {
      &-layout {
        display: grid;
        grid-template-columns: 1fr;
      }

      &-column {
        order: -1;
      }
    }

    &-cell {
      padding: 2rem;

      &-icon {
        width: 4rem;
        height: 6rem;

        & img {
          width: 2.33438rem;
          height: 2.42531rem;
        }
      }

      &__no-borders {
        padding: 0;
      }

      &__spec-border {
        padding: 0 !important;
      }

      &-img {
        padding: 0;

        &__trees-bg {
          width: 100%;
          aspect-ratio: auto;
        }
      }

      &-transparent {
        padding: 0px !important;
      }
    }

    &-transparent {
      flex-wrap: wrap;
      flex-direction: column-reverse;
      gap: 2rem;
      margin-bottom: 2rem;
      margin-right: calc(-0.5 * 1.5rem);
      margin-left: calc(-0.5 * 1.5rem);

      &__img {
        padding: 0;
        padding-right: 0 !important;
        padding-left: 0 !important;
      }
    }

    &__top {
      margin-top: 2rem !important;
    }

    &__first {
      background-color: $white;

      &:before {
        display: none;
      }
    }

    &-spec-bg {
      flex-direction: column;
    }

    &__sale-form {
      flex-direction: column-reverse;
      background-color: $white;

      &:before {
        display: none;
      }
    }
  }

  .img-first-layer {
    display: none;
  }

  .img-center-layer__top {
    display: none;
  }

  .img-two {
    padding: 0 2rem 2rem 2rem;

    img {
      height: 100%;
      width: 100%;
      min-height: 12.58425rem;
      min-width: 8.70788rem;
    }
  }

  .article-column__no-borders .article-cell__no-borders {
    padding: 0;
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .img {
    &-spec-bg {
      width: 100%;
      height: 14rem;
      border-bottom-left-radius: 1rem;
      border-bottom-right-radius: 1rem;

      & div {
        background-position: center !important;
      }
    }

    &-full {
      width: 20rem;
      border-radius: 1rem;
    }

    &-sale {
      aspect-ratio: auto !important;
      width: 100%;
    }
  }
}
