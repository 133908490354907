.btn.btn-sextant{
    background-color: $red;
    color:$white;
    border-radius: 35px;

    text-align: center;
    /* Body text bold */
    font-family: "Open Sans";
    font-size: 1rem;
    font-weight: 400;
    padding: 1rem 1rem;

    &-secondary {
         color: $blue;
    }
}

.btn-container-flex{
    justify-content: center;
    display: flex;
    padding: 2.5rem;

    > .btn{
        max-width: 330px;
        width: fit-content!important;
        padding: 1rem 3.75rem;
    }

    &__short {
        padding: 1rem;
    }
}

@media only screen and (max-width: 768px) {
    .btn-container-flex {
        padding: 0;
    }
}